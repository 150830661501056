// ** React Imports
import { useState, createContext } from "react";

// ** Create Context
const TabContext = createContext();

const TabProviderWrapper = ({ children }) => {
    const [tab, setTab] = useState("contracts");

    const switchtabs = async (tab) => {
        setTab(tab);
    };

    return <TabContext.Provider value={{ tab, switchtabs }}>{children}</TabContext.Provider>;
};

export { TabContext, TabProviderWrapper };
