import { filterTypes } from "../../types/customFilter/types";

// ** Initial State
const initialState = {
    leads: {},
    customers: {},
    contracts: {},
    reports: {},
    forecasts: {},
    tasks: {},
};

const customFilter = (state = initialState, action) => {
    switch (action.type) {
        case filterTypes.inputChange:
            return {
                ...state,
                [action.payload.endPoint]: {
                    ...state[action.payload.endPoint],
                    [action.payload.name]: action.payload.value,
                },
            };
        case filterTypes.cleanFilter:
            return {
                ...state,
                [action.payload.endPoint]: {},
            };
        default:
            return state;
    }
};

export default customFilter;
